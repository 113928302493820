export const skills = [
	{
        id:1,
        name:'HTML5',
        color:'#e34f26',
        percentage:74
		
	},
   {
      id:2,
      name:'React',
      color:'#5adafd',
      percentage:45
   },
	{
        id:3,
        name:'CSS3',
        color:'#264de4',
        percentage:70
	},
  
   
       {
         id:4,
         name:'JavaScript',
         color:'#ffc107',
         percentage:55
      },
         {
        id:5,
        name:'Symfony',
        color:'#e48e00',
        percentage:25
     },
     {
        id:6,
        name:'NodeJS',
        color:'#74aa64',
        percentage:30
     },
     {
        id:7,
        name:'PHP',
        color:'#7c0af8',
        percentage:30
     },
     {
      id:8,
      name:'Sass',
      color:'#be608b',
      percentage:35
   },
     
      
	
]