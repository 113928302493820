import React from 'react'
import './Footer.scss'

export default function Footer() {
  return (
    <footer>
    
    <h3>By Ouadie {new Date().getFullYear()}.</h3>
</footer>
  )
}
