export const languages = [
	
   {
      id:1,
      name:'Français',
      color:'#0055A4',
      percentage:100,
      level:'Avancé',
   },
	{
        id:2,
        name:'Arabe',
        color:'rgb(190, 188, 188)',
        percentage:100,
        level:'Maternelle',

	},
        {
                id:3,
                name:'Anglais',
                color:'#dc3545',
                percentage:100,
                level:'Avancé'
                        
                },
      
	
]