import React from 'react'
import Banner from '../../components/Banner'
import Skills from '../../components/Skills'
import Propos from '../../components/Propos'


export default function Home() {
  return (<>
        <Banner/>
        <Propos/>
        <Skills/>
        </>)
}
